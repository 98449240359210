<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Компания"
              label-for="company"
            >
              <b-form-input
                id="company"
                readonly
                :state="get(validation, 'company_id[0]') ? false : null"
                :value="$store.getters['workingMode/selected_company_name']"
              />
              <small
                v-if="!!get(validation, 'company_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'company_id[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Название"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название проекта"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BAlert, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createProject } from '@/services/main-api/business/projects'

export default {
  name: 'CreateProjectView',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BAlert,
    BLink,
  },
  data() {
    return {
      item: {
        name: '',
        company_id: null,
      },
      companies: [],
      validation: {},
      error_message: '',
      loading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      this.loading = false
    },
    async createItem() {
      this.clearValidation()
      this.loading = true
      try {
        await createProject({
          name: this.item.name,
          company_id: this.$store.getters['workingMode/selected_company_id'],
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Создано!',
            icon: 'BellIcon',
            text: 'Проект была создан.',
            variant: 'success',
          },
        })
        this.clearForm()
        await this.$router.push({ name: 'company.show' })
        this.$store.dispatch('workingMode/getData')
      } catch (e) {
        this.validation = get(e.response, 'data.errors')
        this.error_message = get(e.response, 'data.error', '')
      } finally {
        this.loading = false
      }
    },
    get,
    clearValidation() {
      this.validation = {}
      this.error_message = ''
    },
    clearForm() {
      this.validation = {}
      this.error_message = ''
      this.item.name = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
